.button-parent {
  display: flex;
  height: 99%;
  width: 10%;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.63);

  &:after {
    content: "";
    width: 0;
    position: absolute;
    height: 0;
    top: 0px;
    left: 100%;
    border-top: 25px solid transparent;
    border-bottom: 25px solid transparent;
    border-left: 20px solid #17D7A0;
  }

  &.accordion-show {
    .btn-accordion {
      transform: rotate(180deg);
      top: 3px;
    }

    left: 0%;
    animation-timing-function: ease;
    animation-name: accordionShow;
    animation-duration: 0.5s;
  }

  /* Standard syntax */
  @keyframes accordionShow {
    0% {
      left: -12.6%;
    }

    100% {
      left: 0%;
    }
  }

  &.accordion-hide {
    .btn-accordion {
      transform: rotate(0deg);
      top: -3px;
    }

    left: -12.6%;
    animation-timing-function: ease;
    animation-name: accordionHide;
    animation-duration: 0.5s;
  }

  /* Standard syntax */
  @keyframes accordionHide {
    0% {
      left: 0%;
    }

    100% {
      left: -12.6%;
    }
  }

  @media (max-width: 1366px) {
    height: auto;
  }
}

.anatomy-buttons-wrapper {
  width: 100%;
  height: 15%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  button {

    &.btn:focus,
    &.btn.focus,
    &.btn:active:focus,
    &.btn:active.focus,
    &.btn.active:focus,
    &.btn.active.focus {
      outline: none;
    }

    &.btn:active,
    &.btn.active {
      box-shadow: none;
    }

    background-color: transparent;
    text-align: center;
    width: 100%;
    height: 100%;
    padding: 10px 12px;

    label {
      color: #17D7A0;
      font-size: 15px;
      font-weight: normal;
      display: block;
    }

    &.active {
      label {
        color: white;
      }
    }

    img {
      margin: 0px auto;
      max-width: 30%;
    }

    &:hover {
      outline: none;

      label {
        color: black;
      }
    }
  }

  &:hover,
  &:focus {
    background-color: #17D7A0;
    outline: none;
  }
}

.anatomy-canvas {
  // margin-bottom: -4px;
  position: relative;
  height: 100%;
}

.btn-accordion {
  background-color: transparent;
  border: 0px;
  position: absolute;
  top: -3px;
  left: 100%;
  padding: 0px;
  color: black;
  font-size: 33px;
  z-index: 1;

  &:hover,
  &:focus {
    outline: none;
  }
}

.anatomy-extra {
  position: absolute;
  right: 15px;
  bottom: 15px;

  &:hover {
    transform: scale(1.05);
    outline: none;
  }

  &:focus {
    outline: none;
  }

  button {
    min-width: 50px;
    width: 50px;
  }

  img {
    width: 100%;
  }
}

// Loading animation
.cssload-loader {
  margin: 0px auto;
  left: calc(50% - 31px);
  top: calc(50% - 31px);
  width: 100px;
  height: 100px;
  border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  perspective: 780px;
}

.cssload-inner {
  position: absolute;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
}

.cssload-inner.cssload-one {
  left: 0%;
  top: 0%;
  animation: cssload-rotate-one 1.15s linear infinite;
  -o-animation: cssload-rotate-one 1.15s linear infinite;
  -ms-animation: cssload-rotate-one 1.15s linear infinite;
  -webkit-animation: cssload-rotate-one 1.15s linear infinite;
  -moz-animation: cssload-rotate-one 1.15s linear infinite;
  border-bottom: 3px solid rgb(0, 0, 0);
}

.cssload-inner.cssload-two {
  right: 0%;
  top: 0%;
  animation: cssload-rotate-two 1.15s linear infinite;
  -o-animation: cssload-rotate-two 1.15s linear infinite;
  -ms-animation: cssload-rotate-two 1.15s linear infinite;
  -webkit-animation: cssload-rotate-two 1.15s linear infinite;
  -moz-animation: cssload-rotate-two 1.15s linear infinite;
  border-right: 3px solid rgb(0, 0, 0);
}

.cssload-inner.cssload-three {
  right: 0%;
  bottom: 0%;
  animation: cssload-rotate-three 1.15s linear infinite;
  -o-animation: cssload-rotate-three 1.15s linear infinite;
  -ms-animation: cssload-rotate-three 1.15s linear infinite;
  -webkit-animation: cssload-rotate-three 1.15s linear infinite;
  -moz-animation: cssload-rotate-three 1.15s linear infinite;
  border-top: 3px solid rgb(0, 0, 0);
}

@keyframes cssload-rotate-one {
  0% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
  }

  100% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
  }
}

@-o-keyframes cssload-rotate-one {
  0% {
    -o-transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
  }

  100% {
    -o-transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
  }
}

@-ms-keyframes cssload-rotate-one {
  0% {
    -ms-transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
  }

  100% {
    -ms-transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
  }
}

@-webkit-keyframes cssload-rotate-one {
  0% {
    -webkit-transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
  }

  100% {
    -webkit-transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
  }
}

@-moz-keyframes cssload-rotate-one {
  0% {
    -moz-transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
  }

  100% {
    -moz-transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
  }
}

@keyframes cssload-rotate-two {
  0% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
  }

  100% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
  }
}

@-o-keyframes cssload-rotate-two {
  0% {
    -o-transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
  }

  100% {
    -o-transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
  }
}

@-ms-keyframes cssload-rotate-two {
  0% {
    -ms-transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
  }

  100% {
    -ms-transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
  }
}

@-webkit-keyframes cssload-rotate-two {
  0% {
    -webkit-transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
  }

  100% {
    -webkit-transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
  }
}

@-moz-keyframes cssload-rotate-two {
  0% {
    -moz-transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
  }

  100% {
    -moz-transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
  }
}

@keyframes cssload-rotate-three {
  0% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
  }

  100% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
  }
}

@-o-keyframes cssload-rotate-three {
  0% {
    -o-transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
  }

  100% {
    -o-transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
  }
}

@-ms-keyframes cssload-rotate-three {
  0% {
    -ms-transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
  }

  100% {
    -ms-transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
  }
}

@-webkit-keyframes cssload-rotate-three {
  0% {
    -webkit-transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
  }

  100% {
    -webkit-transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
  }
}

@-moz-keyframes cssload-rotate-three {
  0% {
    -moz-transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
  }

  100% {
    -moz-transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
  }
}